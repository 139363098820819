<template>

  <div>
    <b-modal
      id="invoiceSendModal"
      ref="invoiceSendModal"
      title="Send Invoice Mail"
      hide-footer
      size="lg"
    >
      <invoice-send
        v-if="selectedInvoice"
        :invoice-data="selectedInvoice"
        @handleSend="handleInvoiceSend"
      />
    </b-modal>
    <upgrade-plan-modal ref="refUpgradePlanModal" />
    <upgrade-plan-for-feature-modal ref="refUpgradePlanForFeatureModal" />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>

        <div
          v-if="firstDataCreated==false && pageIsLoading==false"
          class="m-2"
        >

          <b-row>
            <b-col md="12">
              <div class="d-flex flex-column align-items-center">
                <feather-icon
                  icon="FileTextIcon"
                  size="70"
                />
                <h1 class="mt-2">
                  Add Recurring Invoice
                </h1>
                <span>Save your customers’ contact details for faster invoicing.</span>
                <b-button
                  class="mt-4"
                  variant="primary"
                  @click="handleCreateInvoiceButton"
                >
                  <span class="text-nowrap">{{ $t('Create Recurring Invoice') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <div
          v-else
          class="m-2"
        >
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            filename="invoice"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"

            pdf-content-width="800px"

            @startPagination="pdfHasStartedGeneration()"
            @hasPaginated="pdfHasGenerated($event)"
          >
            <section slot="pdf-content">
              <invoice-preview
                v-if="showPrint"
                :invoice-data="selectedInvoice"
              />
            </section>
          </vue-html2pdf>
          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />

            <!-- Search -->
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex justify-content-between align-items-center column-is-mobile">

                <b-button
                  size="lg"
                  variant="primary"
                  @click="handleCreateInvoiceButton"
                >
                  <span class="text-nowrap">{{ $t('Create a recurring invoice') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <hr>
        </div>
        <div>

          <b-table
            ref="refInvoiceListTable"
            style="min-height:300px;"
            class="position-relative"
            :items="fetchTickets"
            responsive
            :fields="tableColumns"
            primary-key="id"
            select-mode="single"
            :sort-by.sync="sortBy"
            striped
            hover

            show-empty
            :sort-desc.sync="isSortDirDesc"
            selectable
            empty-text="No matching records found"
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              <span class="font-weight-bold d-block text-nowrap">{{ data.item.name }}</span>
            </template>
            <template #cell(content.billing)="data">
              <span v-if="!data.item.content.billing"> [Invoice not selected]</span>
              <span v-else> {{ data.item.content.billing.name }}</span>
            </template>
            <template #cell(content.invoice_date)="data">
              <span> {{ $format_date(data.item.content.invoice_date) }}</span>

            </template>
            <template #cell(content.recurring_settings.enabled)="data">
              <b-badge
                v-if="data.item.content.current_occurences == data.item.content.recurring_settings.number_of_occurences"
                variant="info"
              >
                Completed
              </b-badge>

              <b-badge
                v-else-if="data.item.content.recurring_settings.enabled"
                variant="success"
              >
                Enabled
              </b-badge>
              <b-badge
                v-else
                variant="secondary"
              >
                Disabled
              </b-badge>

            </template>

            <template #cell(content.recurring_settings.number_of_occurences)="data">
              <strong
                v-if="data.item.content.recurring_settings.number_of_occurences"
                variant="success"
              >
                {{ data.item.content.recurring_settings.number_of_occurences }}
              </strong>
              <b-badge
                v-else
                variant="warning"
              >
                Endless
                <feather-icon
                  icon="RepeatIcon"
                  size="14"
                />
              </b-badge>

            </template>
            <template #cell(content.recurring_settings.recurring_period)="data">
              <span> {{ $staticParams.recurringPeriodOptions.find(a=>a.value ==data.item.content.recurring_settings.recurring_period).label }}</span>

            </template>
            <template #cell(content.paid_date)="data">
              <span> {{ $format_date(data.item.content.paid_date) }}</span>

            </template>
            <template #cell(total)="data">
              <span> {{ data.item.content.currency_code }} {{ $staticParams.formatCurrency(calculateTotals(data.item).total) }}</span>

            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.content.current_occurences>0"
                  variant="primary"
                  :to="{ name: 'recurring-invoices-edit', params: { id: data.item.id,activeTab:'recurred-invoices' } }"
                >

                  <span class="align-middle font-weight-bolder">{{ $t('View Recurred Invoices') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="false"
                  variant="primary"
                  @click="duplicateRecurringInvoice(data.item)"
                >
                  <span class="align-middle font-weight-bolder">{{ $t('Duplicate Recurring') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="deleteData(data.item.id, data.item.content.current_occurences)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50 font-weight-bolder">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalInvoices"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>

        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BPagination, BButton, BOverlay, BSpinner, BModal, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import invoiceCalculations from '@core/components/invoice-themes/calculations'
import invoicePreview from '@core/components/invoice-themes/Template.vue'
import VueHtml2pdf from 'vue-html2pdf'
import upgradePlanModal from '@core/components/information-components/UpgradePlanModal.vue'
import upgradePlanForFeatureModal from '@core/components/information-components/UpgradePlanForFeatureModal.vue'

// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import invoiceStoreModule from './invoiceStoreModule'
import invoiceSend from './InvoiceSend.vue'

export default {
  components: {
    BCard,
    BRow,
    invoiceSend,
    BBadge,
    BModal,
    upgradePlanModal,
    upgradePlanForFeatureModal,
    BCol,
    VueHtml2pdf,
    BTable,
    BDropdown,
    invoicePreview,
    BDropdownItem,
    BPagination,
    BButton,
    BOverlay,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedInvoice: null,
      showPrint: false,
      overlayMsg: 'Loading...',
    }
  },
  computed: {

  userData() {
    return this.$store.state.app.userData
  },
  },
  watch: {
    filterType() {
      this.$refs.refInvoiceListTable.refresh()
    },
  },
  methods: {
    openInvoiceSendModal(invoiceData) {
      this.selectedInvoice = invoiceData
      this.$refs.invoiceSendModal.show()
    },
    handleInvoiceSend(sendData) {
      // this.saveData()
      this.sendInvoiceMail(sendData)
    },
    sendInvoiceMail(mailContent) {
      this.overlayMsg = 'Invoice sending to mail...'
      this.pageIsLoading = true
      store
        .dispatch('app-recurring-invoice/sendMail', { invoice_id: this.selectedInvoice.id, content: mailContent })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice sended'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice sent by e-mail',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    duplicateRecurringInvoice(invoiceData) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('The invoice will be duplicated with the recurring settings. Do you want to continue?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, continue'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
      this.overlayMsg = 'Duplicating the invoice...'
      this.pageIsLoading = true
      store
        .dispatch('app-invoice/duplicateRecurringInvoice', invoiceData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isSuccess = true
          this.errorData = null
          this.successMsg = 'Invoice duplicated'
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice duplicated',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'invoices-edit', params: { id: response.data.data.id } })
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
      }
    })
    },
    handleCreateInvoiceButton() {
      if (this.userData.subscription.plan_name === 'Basic') {
        this.$refs.refUpgradePlanForFeatureModal.show()
      } else {
        const { limits } = this.$user()
      let access = true
      if (limits.remaining_invoice_limit != null) {
        if (limits.remaining_invoice_limit <= 0) {
          access = false
          this.$refs.refUpgradePlanModal.show()
        }
      }

      if (access) {
        this.$destroy()
        this.$router.replace({ name: 'recurring-invoices-add' })
      }
      }
    },
    pdfHasStartedGeneration() {

    },
    toPdf(invoiceData) {
      this.overlayMsg = 'Printing...'
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/recurring-invoices-download/${invoiceData.id}?token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
      /* changed as backend downloadable
      this.pageIsLoading = true
      this.selectedInvoice = invoiceData
      this.showPrint = true

      setTimeout(() => this.$refs.html2Pdf.generatePdf(), 3000)
      */
    },
    pdfHasGenerated() {
      setTimeout(() => {
        this.showPrint = false
        this.selectedInvoice = false
        this.pageIsLoading = false
        this.overlayMsg = 'Loading...'
      }, 3000)
    },

    calculateTotals(invoiceData) {
      return invoiceCalculations.calculateTotals(invoiceData).content.totals
    },
    onRowSelected(items) {
       this.$destroy()
      this.$router.replace({ name: 'recurring-invoices-edit', params: { id: items[0].id } })
    },
    deleteData(id, occurences) {
      if (occurences === 0) {
        this.$swal({
        title: this.$t('Are you sure ?'),
        text: this.$t('You are about to delete a invoice. This action cannot be undone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete now'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-recurring-invoice/deleteInvoice', { id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice was deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refInvoiceListTable.refresh()
            })
            .catch(() => {

            })
        }
      })
      } else {
        this.$swal({
                title: this.$t('Error'),
                text: this.$t('There are existing invoices created for the selected recurring invoice. Please delete the related invoices and try again. '),
                icon: 'warning',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
      }
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-recurring-invoice'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    const filterType = ref('unpaid')
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalInvoices = ref(0)
    const firstDataCreated = ref(false)
    const refInvoiceListTable = ref(null)
    const currentPage = ref(1)
    const pageIsLoading = ref(true)
    const sortBy = ref('content.invoice_date')
    const isSortDirDesc = ref(true)
    const unpaidTableColumns = [

      { key: 'content.billing', label: 'INVOICE', sortable: true },
      { key: 'content.recurring_settings.start_date', label: 'Start Date', sortable: true },
      { key: 'content.recurring_settings.end_date', label: 'End date', sortable: true },
      { key: 'content.recurring_settings.recurring_period', label: 'RECURRING PERIOD', sortable: false },
{ key: 'content.recurring_settings.enabled', label: 'RECURRING Status', sortable: false },
{ key: 'content.recurring_settings.number_of_occurences', label: 'Number Of Occurences', sortable: false },
{ key: 'content.current_occurences', label: 'Current Occurences', sortable: false },

      {
        key: 'actions', thClass: 'text-right', label: 'ACTIONS', tdClass: 'text-right',
      },
    ]
    const paidTableColumns = [
      {
        key: 'content.invoice_number', label: 'Number', sortable: true,
      },
      { key: 'content.billing', label: 'Invoice', sortable: true },
      { key: 'content.invoice_date', label: 'Date', sortable: true },
      { key: 'content.paid_date', label: 'Paid date', sortable: true },
      { key: 'total', label: 'Total', sortable: false },

      { key: 'actions', thClass: 'text-right', tdClass: 'text-right' },
    ]
    const tableColumns = ref(unpaidTableColumns)

    const refetchData = () => {
      refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalInvoices.value,
      }
    })

    const fetchTickets = (ctx, callback) => {
      pageIsLoading.value = true
      store
        .dispatch('app-recurring-invoice/fetchInvoices', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filterType: filterType.value,
        })
        .then(response => {
          if (filterType.value === 'paid') {
            tableColumns.value = paidTableColumns
          } else if (filterType.value === 'unpaid') {
            tableColumns.value = unpaidTableColumns
          }
          pageIsLoading.value = false
          const { data } = response.data
          const { total } = response.data.meta
          callback(data)
          totalInvoices.value = total
          firstDataCreated.value = response.data.meta.first_invoice_created
        })
        .catch(() => {
          pageIsLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching tickets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      tableColumns,
      // Filter
      perPageOptions,
      perPage,
      dataMeta,
      fetchTickets,
      searchQuery,
      totalInvoices,
      firstDataCreated,
      currentPage,
      filterType,
      refetchData,
      // UI
      refInvoiceListTable,
      pageIsLoading,
      isSortDirDesc,
      sortBy,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
@media (max-width: 800px) {
  .column-is-mobile {
    flex-direction: column;
  }
}
.table-width{
  max-width:150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
