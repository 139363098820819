import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/recurring-invoices/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoices(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/recurring-invoices', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/recurring-invoices', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/recurring-invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoiceSingle(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/recurring-invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/recurring-invoices-send-mail', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    markAsPaid(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/recurring-invoices-mark-as-paid', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // payment types
    fetchPaymentTypes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/payment-methods', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchRecurredInvoices(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/fetch-recurred-invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/invoices-duplicate/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createLimitedInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
        .post(`/api/create-limited-invoice/${data.id}`, data)
        .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
